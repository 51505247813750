<template>
  <div>
    <div class="mb-5">
      <v-autocomplete
        class="c-input-small"
        v-model="id_reason"
        :items="reasonOptions"
        :placeholder="$t('labels.reason')"
        :label="$t('labels.reason')"
        :readonly="isLoading"
        ref="reasonRef"
        @change="onReasonChanged"
        dense
        outlined
        hide-details
      ></v-autocomplete>
    </div>

    <div class="mb-5" v-if="isRequireNote">
      <v-textarea
        class="c-input-small"
        v-model="note"
        :placeholder="$t('labels.note')"
        :label="$t('labels.note')"
        :single-line="false"
        :rows="2"
        :readonly="isLoading"
        ref="noteRef"
        dense
        outlined
        clearable
        hide-details
      ></v-textarea>
    </div>

    <div class="mb-5">
      <input-qr-scan-model
        v-model="code"
        ref="codeRef"
        :disabled="isDisabledCodeInput"
        :label="$t('labels.uid_sku_barcode')"
        @keyupEnter="scanInput"
      ></input-qr-scan-model>
    </div>

    <div class="error--text text-center" v-if="isLoading">
      {{ $t("messages.processing_wait") }}
    </div>
  </div>
</template>

<script>
import {
  downloadExcelFile,
  formatDateTime,
  isMobile,
  hideString,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { REASON_TYPE_EMPLOYEE_EXPORT_ITEM } from "@/libs/const";

export default {
  name: "EmployeeExport",
  components: {},
  data: () => ({
    isLoading: false,
    code: null,
    note: null,
    id_reason: null,
    reasons: [],
    items: [],
  }),
  computed: {
    reasonOptions() {
      return [...this.reasons].map((r) => ({
        text: r[`${this.$i18n.locale}_description`] || r["description"],
        value: r.id,
      }));
    },
    isDisabledCodeInput() {
      return !this.id_reason || (this.isRequireNote && !this.note);
    },
    isRequireNote() {
      if (!this.id_reason) {
        return false;
      }

      const selected = [...this.reasons].find((r) => r.id == this.id_reason);
      return !!selected.is_require_note;
    },
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    downloadExcelFile,
    formatDateTime,
    isMobile,
    hideString,
    onReasonChanged() {
      this.$refs.reasonRef.blur();
      setTimeout(() => {
        if (!this.isRequireNote) {
          this.$refs.codeRef.focusInput();
        } else {
          this.$refs.noteRef.focus();
        }
      }, 500);
    },
    getReasons() {
      httpClient
        .post("/common/v1/get-reason", {
          type: REASON_TYPE_EMPLOYEE_EXPORT_ITEM,
        })
        .then(({ data }) => {
          this.reasons = [...data];
        });
    },
    async scanInput() {
      if (!this.code) {
        return false;
      }

      this.isLoading = true;
      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/employee-export-order",
          {
            code: this.code,
            id_reason: this.id_reason,
            note: this.note,
          }
        );

        this.code = null;

        // this.$vToastify.success(this.$t("messages.create_success"));
        // document.getElementById("success_sound_player").play();
        setTimeout(() => {
          this.processOrder(data);
        }, 2000);
        return true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async processOrder(input) {
      try {
        await httpClient.post(
          "/goods-issue-detail/v1/employee-export-order-change-status",
          input
        );
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;

        if (errMsg === "WaitProcess") {
          return this.processOrder(input);
        }

        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
